/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"

import { renderLineBreak } from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import Quote from "../components/design-partner/quote"
import Hero from "../components/hero"
import ModelSlider from "../components/model-slider"
import Row from "../components/row"
import Text from "../components/text"
import PageLink from "../components/page-link"
import ProjectCard from "../components/project-card"

const DesignPartnerDetail = ({ data }) => {
  const renderDesignerPartnerInfoBladePattern = () => {
    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row>
            <Column size={[12, 2]}>
              <img
                src={data.contentfulDesignPartner.photo.file.url}
                alt=""
                sx={{
                  borderRadius: "50%",
                  width: 170,
                }}
              />
            </Column>
            <Column size={[12, 8]}>
              <Text type="h1">
                {renderLineBreak(data.contentfulDesignPartner.name)}
              </Text>
              {data.contentfulDesignPartner.website ? (
                <PageLink
                  link={data.contentfulDesignPartner.website}
                  customStyle={{
                    fontSize: 3,
                  }}
                >
                  {data.contentfulDesignPartner.website}
                </PageLink>
              ) : null}
              {data.contentfulDesignPartner.designerDetails ? (
                <Row
                  customStyle={{
                    mt: theme => [theme.spacing.vertical.md],
                  }}
                >
                  {data.contentfulDesignPartner.designerDetails.map(detail => (
                    <Column size={[4]} key={kebabCase(detail.title)}>
                      <div>
                        <Text>{detail.title}</Text>
                      </div>
                      <div>
                        {detail.description &&
                          documentToReactComponents(detail.description.json, {
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (node, children) => (
                                <Text
                                  customStyle={{
                                    mt: theme => [0, theme.spacing.vertical.sm],
                                    fontSize: 3,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {children}
                                </Text>
                              ),
                            },
                          })}
                      </div>
                    </Column>
                  ))}
                </Row>
              ) : null}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderDesignPartnerFeaturedWork = () => {
    const fullWidthList = [true, true, true]

    if (!data.contentfulDesignPartner.featuredWork) return
    return (
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            <Carousel type="tertiary">
              {data.contentfulDesignPartner.featuredWork.map(
                (featuredWork, idx) => (
                  <Hero
                    key={idx}
                    background={{
                      type: "image",
                      source: {
                        desktopImage: { file: { url: featuredWork.file.url } },
                      },
                    }}
                    type="primary"
                  />
                )
              )}
            </Carousel>
          </Column>
        </Row>
      </Container>
    )
  }
  const renderDesignPartnerBio = () => {
    if (!data.contentfulDesignPartner.bio) return
    return (
      <div>
        <Container>
          <Row>
            <Column
              customStyle={{
                alignItems: "center",
              }}
            >
              <div
                sx={{
                  maxWidth: 600,
                  py: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.lg,
                  ],
                }}
              >
                {data.contentfulDesignPartner.bio &&
                  documentToReactComponents(
                    data.contentfulDesignPartner.bio.json,
                    {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <Text
                            customStyle={{
                              mt: theme => [0, theme.spacing.vertical.sm],
                            }}
                          >
                            {children}
                          </Text>
                        ),
                      },
                    }
                  )}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderDesignerPartnerQuote = () => {
    if (!data.contentfulDesignPartner.quote) return

    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Quote
          name={data.contentfulDesignPartner.name}
          quote={data.contentfulDesignPartner.quote}
          photo={data.contentfulDesignPartner.photo}
        />
      </div>
    )
  }
  const renderDesignerProjects = () => {
    if (!data.allContentfulProject.edges.length) return

    return (
      <div
        sx={{
          pt: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row
            customStyle={{
              mb: theme => theme.spacing.vertical.lg,
            }}
          >
            <Text type="h2">
              {renderLineBreak(
                `Projects by\n${data.contentfulDesignPartner.name}`
              )}
            </Text>
          </Row>
          <Row>
            {data.allContentfulProject.edges.map(project => (
              <Column
                size={[12, 6]}
                customStyle={{
                  mb: theme => [theme.spacing.vertical.sm],
                }}
                key={kebabCase(project.node.projectTitle.projectTitle)}
              >
                <PageLink
                  link={`/projects/${project.node.slug}`}
                  type="internal"
                >
                  <ProjectCard
                    background={project.node.projectImages[0].file.url}
                    text={[
                      project.node.projectTitle.projectTitle,
                      project.node.projectLocation.projectLocation,
                    ]}
                  />
                </PageLink>
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  const renderDesignerPartnerLivingHomes = () => {
    if (!data.allContentfulModelVariation.edges.length) return

    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        <Container>
          <Row
            customStyle={{
              mb: theme => theme.spacing.vertical.sm,
            }}
          >
            <Text type="h2">
              {renderLineBreak(
                `LivingHomes by\n${data.contentfulDesignPartner.name}`
              )}
            </Text>
          </Row>
          <Row>
            <Column>
              <ModelSlider
                data={data.allContentfulModelVariation}
                type="twoColumns"
              />
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderBrowseByDesigner = () => {
    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        <Container>
          <Row
            customStyle={{
              mb: theme => theme.spacing.vertical.sm,
            }}
          >
            <Text type="h3">Browse by Designer</Text>
          </Row>
          <Row>
            {data.allContentfulDesignPartner.edges.map(designPartner => (
              <Column
                size={[12, 6, 4]}
                customStyle={{
                  mb: theme => theme.spacing.vertical.sm,
                }}
                key={kebabCase(designPartner.node.name)}
              >
                <PageLink
                  link={`/architects/${designPartner.node.slug}`}
                  type="internal"
                >
                  <Flex
                    sx={{
                      alignItems: "center",
                      border: "1px solid #cccccc",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      sx={{
                        width: 90,
                        height: "auto",
                        mr: theme => theme.spacing.horizontal,
                      }}
                      src={designPartner.node.photo.file.url}
                      alt=""
                    />
                    <div>
                      <Text
                        customStyle={{
                          fontSize: 2,
                          fontWeight: "bold",
                        }}
                      >
                        {designPartner.node.name}
                      </Text>
                      <Text>{designPartner.node.name}</Text>
                    </div>
                  </Flex>
                </PageLink>
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div>
      {renderDesignerPartnerInfoBladePattern()}
      {renderDesignPartnerFeaturedWork()}
      {renderDesignPartnerBio()}
      {renderDesignerPartnerQuote()}
      {renderDesignerProjects()}
      {renderDesignerPartnerLivingHomes()}
      {renderBrowseByDesigner()}
    </div>
  )
}

export default DesignPartnerDetail

export const query = graphql`
  query($slug: String!) {
    contentfulDesignPartner(slug: { eq: $slug }) {
      name
      bio {
        json
      }
      quote {
        quote {
          json
        }
      }
      photo {
        file {
          url
        }
      }
      shortBio {
        json
      }
      project {
        childContentfulProjectProjectTitleTextNode {
          projectTitle
        }
        projectImages {
          file {
            url
          }
        }
      }
      featuredWork {
        file {
          url
        }
      }
      designerDetails {
        title
        description {
          json
        }
      }
    }

    allContentfulProject(
      limit: 2
      sort: { fields: createdAt, order: DESC }
      filter: { designPartner: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          slug
          projectTytpe
          projectTitle {
            projectTitle
          }
          projectLocation {
            projectLocation
          }
          projectDescription {
            json
          }
          projectImages {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulDesignPartner(filter: { slug: { ne: $slug } }) {
      edges {
        node {
          name
          slug
          photo {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulModelVariation(
      sort: { fields: createdAt, order: DESC }
      filter: { model: { designPartner: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          baths
          beds
          id
          images {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          model {
            id
            slug
            designPartner {
              slug
            }
          }
          name
          squareFootage
        }
      }
    }
  }
`
